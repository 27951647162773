<template>
  <div class="pageContol listWrap">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">系统设置</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">课程使用设置</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 5px">
            <div title="配置类型" class="searchboxItem ci-full">
              <span class="itemLabel">配置类型:</span>
              <el-select
                clearable
                size="small"
                v-model="retrievalData.usesetType"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in usesetTypeData"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <div
              title="行政区划"
              class="searchboxItem ci-full"
              v-if="retrievalData.usesetType == '20'"
            >
              <span class="itemLabel">行政区划:</span>
              <el-cascader
                size="small"
                :props="{ emitPath: false, checkStrictly: true }"
                v-model="retrievalData.areaId"
                :options="cityTree"
                @change="handleCascader"
                clearable
              ></el-cascader>
            </div>
            <div
              title="机构名称"
              class="searchboxItem ci-full"
              v-if="retrievalData.usesetType == '10'"
            >
              <span class="itemLabel">机构名称:</span>
              <el-select
                size="small"
                v-model="retrievalData.compId"
                remote
                :remote-method="getCompanyList"
                filterable
                clearable
                placeholder="请至少输入两个字搜索"
              >
                <el-option
                  v-for="item in CompanyList"
                  :key="item.compId"
                  :label="item.compName"
                  :value="item.compId"
                ></el-option>
              </el-select>
            </div>
            <div class="btnBox" style="margin-left: 20px">
              <el-button
                style="margin-left: 20px"
                class="bgc-bv"
                round
                @click="getData()"
                >查询</el-button
              >
              <el-button
                style="margin-left: 20px"
                class="bgc-bv"
                round
                @click="addEdit()"
                >新增</el-button
              >
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                width="100px"
                :index="indexMethod"
              />
              <el-table-column
                label="配置类型"
                align="left"
                prop="usesetType"
                show-overflow-tooltip
                min-width="100"
                ><template slot-scope="scope">
                  {{ $setDictionary("COURSE_USESET", scope.row.usesetType) }}
                </template>
              </el-table-column>
              <el-table-column
                label="行政区划"
                align="left"
                prop="areaName"
                show-overflow-tooltip
                min-width="100"
              />
              <el-table-column
                label="机构名称"
                align="left"
                prop="compName"
                show-overflow-tooltip
                min-width="100"
              />
              <el-table-column
                label="提示内容"
                align="left"
                prop="tipcontent"
                show-overflow-tooltip
                min-width="250"
              />
              <el-table-column
                label="创建时间"
                align="left"
                prop="createTime"
                show-overflow-tooltip
                min-width="160"
              >
                <template slot-scope="scope">
                  <span>{{ scope.row.createTime | momentDate }}</span>
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center" width="100px">
                <div slot-scope="scope">
                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    @click="addEdit(scope.row)"
                    >编辑</el-button
                  >
                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    @click="deleteData(scope.row)"
                    >删除</el-button
                  >
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
    <el-dialog
      :title="addEditDialogData.dialogTitle"
      :visible.sync="addEditDialogVisible"
      width="600px"
      :before-close="handleClose"
      ><el-form
        :model="addEditDialogData"
        ref="addEditDialogData"
        :rules="addEditDialogDataRules"
        label-width="100px"
      >
        <el-form-item label="配置类型：" prop="usesetType">
          <el-select
            clearable
            size="small"
            v-model="addEditDialogData.usesetType"
            placeholder="请选择"
            @change="addEditDialogDataUsesetType"
          >
            <el-option
              v-for="item in usesetTypeData"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="机构名称："
          prop="compId"
          v-if="addEditDialogData.usesetType == '10'"
        >
          <el-select
            size="small"
            v-model="addEditDialogData.compId"
            multiple
            remote
            :remote-method="getCompanyList2"
            filterable
            clearable
            placeholder="请至少输入两个字搜索"
          >
            <el-option
              v-for="item in CompanyList2"
              :key="item.compId"
              :label="item.compName"
              :value="item.compId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="行政区划："
          prop="areaId"
          v-if="addEditDialogData.usesetType == '20'"
        >
          <el-cascader
            size="small"
            :props="{ emitPath: false, checkStrictly: true }"
            v-model="addEditDialogData.areaId"
            :options="cityTree"
            @change="handleCascader"
            clearable
          ></el-cascader>
        </el-form-item>
        <el-form-item label="提示内容：" prop="tipcontent">
          <el-input
            type="textarea"
            :autosize="{ minRows: 4, maxRows: 8 }"
            v-model="addEditDialogData.tipcontent"
            placeholder="请输入提示内容"
            maxlength="200"
            show-word-limit
          >
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="saveData('addEditDialogData')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
export default {
  name: "courseUsageSettings",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      // 检索数据
      retrievalData: {
        usesetType: "10", // 配置类型
        areaId: "", // 行政区划
        compId: "", // 机构名称
      },
      // 配置类型 - 拉下数据
      usesetTypeData: [],
      // 行政区划 - 拉下数据
      cityTree: [],
      // 机构名称 - 下拉数据(查询用)
      CompanyList: [],
      // 机构名称 - 下拉数据(弹框用)
      CompanyList2: [],
      // 新增||编辑 - 弹框
      addEditDialogVisible: false,
      // 新增||编辑 - 弹框 - 数据
      addEditDialogData: {
        dialogTitle: "", // 弹框标题
        usesetId: "", // 当前条的id(课程使用设置id)
        usesetType: "10", // 配置类型
        areaId: "", // 行政区划
        compId: [], // 机构名称
        tipcontent: "", // 提示内容
      },
      // 新增||编辑 - 弹框 - 数据校验
      addEditDialogDataRules: {
        usesetType: [
          { required: true, message: "请选择配置类型", trigger: "change" },
        ],
        areaId: [
          { required: true, message: "请选择行政区划", trigger: "change" },
        ],
        compId: [
          { required: true, message: "请选择机构名称", trigger: "change" },
        ],
        tipcontent: [
          { required: true, message: "请输入提示内容", trigger: "blur" },
        ],
      },
    };
  },
  computed: {},
  created() {
    this.getDictionaries();
    this.getcity();
  },
  methods: {
    // 获取 - 获取字典
    getDictionaries() {
      // 配置类型
      let arr1 = this.$setDictionary("COURSE_USESET", "list");
      for (const key in arr1) {
        this.usesetTypeData.push({
          label: arr1[key],
          value: key,
        });
      }
    },
    // 获取 - 行政区划 - 下拉数据
    getcity() {
      this.$post("/sys/area/tree")
        .then((res) => {
          this.cityTree = res.data || [];
        })
        .catch(() => {
          return;
        });
    },
    // 获取 - 机构名称 - 下拉数据(列表查询条件用)
    getCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then((res) => {
            if (res.status == 0) {
              this.CompanyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.CompanyList = [];
      }
    },
    // 获取 - 机构名称 - 下拉数据(弹框用)
    getCompanyList2(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then((res) => {
            if (res.status == 0) {
              this.CompanyList2 = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.CompanyList = [];
      }
    },
    // 获取 - 列表数据
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        usesetType: this.retrievalData.usesetType,
      };
      if (this.retrievalData.areaId) {
        params.areaId = this.retrievalData.areaId;
      }
      if (this.retrievalData.compId) {
        params.compId = this.retrievalData.compId;
      }
      this.doFetch({
        url: "/course/useset/courseUsesetList",
        params,
        pageNum,
      });
    },
    // 弹框 - 新增||编辑
    addEdit(row) {
      this.addEditDialogVisible = true;
      this.CompanyList2 = [];
      if (row && row.usesetId) {
        this.addEditDialogData.dialogTitle = "编辑";
        this.$post("/course/useset/echoCourseUseset", {
          usesetId: row.usesetId,
        })
          .then((res) => {
            if (res.status == "0") {
              this.addEditDialogData.usesetId = res.data.usesetId;
              this.addEditDialogData.usesetType = res.data.usesetType;
              if (res.data.usesetType == "10") {
                this.addEditDialogData.compId = res.data.areacompId.split(",");
                this.addEditDialogData.compId.map((el) => {
                  this.getCompany2(el);
                });
              }
              if (res.data.usesetType == "20") {
                this.addEditDialogData.areaId = res.data.areacompId;
              }
              this.addEditDialogData.tipcontent =
                res.data.tipcontent ||
                "您选择的课程包含第三方课程，可能会涉及到收取课程服务费，使用前请您联系客服老师确认。";
            }
          })
          .catch(() => {
            return;
          });
      } else {
        Object.keys(this.addEditDialogData).forEach((key) => {
          if (key == "usesetType") {
            this.addEditDialogData[key] = "10";
          } else if (key == "dialogTitle") {
            this.addEditDialogData[key] = "新增";
          } else if (key == "compId") {
            this.addEditDialogData[key] = [];
          } else if (key == "tipcontent") {
            this.addEditDialogData[key] =
              "您选择的课程包含第三方课程，可能会涉及到收取课程服务费，使用前请您联系客服老师确认。";
          } else {
            this.addEditDialogData[key] = "";
          }
        });
      }
    },
    // 弹框 - 多选机构循环回显数据
    getCompany2(id) {
      this.$post("/sys/company/id", { compId: id }).then((res) => {
        this.CompanyList2 = [res.data];
      });
    },
    // 弹框 - 选择配置类型 - 清空已选条件
    addEditDialogDataUsesetType() {
      this.addEditDialogData.areaId = "";
      this.addEditDialogData.compId = "";
    },
    // 弹框 - 取消
    handleClose() {
      this.addEditDialogVisible = false;
    },
    // 弹框 - 确定
    saveData(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let datas = {
            usesetType: this.addEditDialogData.usesetType,
            tipcontent: this.addEditDialogData.tipcontent,
          };
          if (this.addEditDialogData.usesetId) {
            datas.usesetId = this.addEditDialogData.usesetId;
          }
          if (this.addEditDialogData.usesetType == "10") {
            datas.areacompId = this.addEditDialogData.compId.toString();
          }
          if (this.addEditDialogData.usesetType == "20") {
            datas.areacompId = this.addEditDialogData.areaId;
          }
          this.$post(
            this.addEditDialogData.usesetId
              ? "/course/useset/updateCourseUseset"
              : "/course/useset/saveCourseUseset",
            datas
          )
            .then((res) => {
              if (res.status == "0") {
                this.$message({
                  message: this.addEditDialogData.usesetId
                    ? "编辑成功"
                    : "新增成功",
                  type: "success",
                });
                this.addEditDialogVisible = false;
                this.getData(this.pageNum);
              }
            })
            .catch(() => {
              return;
            });
        }
      });
    },
    // 删除
    deleteData(row) {
      this.$confirm("确定删除该条数据吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        confirmButtonClass: "bgc-bv",
        type: "warning",
      })
        .then(() => {
          this.$post("/course/useset/deleteCourseUseset ", {
            usesetId: row.usesetId,
          })
            .then((res) => {
              if (res.status == "0") {
                this.$message({
                  message: "删除成功",
                  type: "success",
                });
                this.getData(this.pageNum);
              }
            })
            .catch(() => {
              return;
            });
        })
        .catch(() => {
          return;
        });
    },
  },
  watch: {},
};
</script>
<style lang="less" scoped>
</style>
